import React from 'react'
import AwardMain from '../pages/award/AwardMain'
import AwardSection1 from '../pages/award/AwardSection1'
import Header from './global/Header'
import Footer from './global/Footer'

function Award() {
    return (
        <>
            <Header/>
            <AwardMain/>
            <AwardSection1/>
            <Footer/>
        </>
    )
}

export default Award
