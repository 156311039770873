import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import blogImg1 from "../../assets/images/blogImg/BlogImg1.png"

function BlogSection1() {
    return (
        <div>
            <Box>
                <Container maxWidth={"lg"}>

                <Box sx={{textAlign:"center",fontSize:"28px",fontWeight:"600",margin:"60px 0 0 0","&:hover":{
          color:"#c29943",
          transition:".3s"
        }}}>Dwarkesh Group Blogs</Box>

                    <Box sx={{ margin: { lg: "40px 0 0 0", xs: "70px 43px 0 0" } }}>
                        <Grid container item xs={12} spacing={4} sx={{ margin: "30px 0" }}>
                            <Grid item xs={12} sm={11} md={5} lg={4} >
                                <Box sx={{ backgroundImage: `url(${blogImg1})`, width: "100%", height: "250px", backgroundSize: "cover", borderRadius: "0px 90px 0px 90px", boxShadow: "-20px -20px 2px #B57E0A", alignItems: "end", display: "flex", flex: "column", justifyContent: "end" }}>
                                    <Button sx={{ bgcolor: "#B57E0A", color: "#fff", border: "2px solid #B57E0A", transition: "0.7s", borderRadius: "0px 20px 0px 20px", marginTop: "auto", "&:hover": { color: "#B57E0A" } }}>Read More</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={11} md={6} lg={8} sx={{justifyContent:"center",display:"flex",alignItems:"center"}}>
                                <Box >
                                    <Typography sx={{ fontSize: "18px", color: "#B57E0A", fontWeight: "500", marginBottom: "5px", paddingBottom: "8px" }} mt={4}>8 jun, 2024</Typography>
                                    <Typography sx={{ fontSize: "22px", fontWeight: "600", lineHeight: "36px" }}>Discover Luxurious Living at Rajhans Maxton: Where Dreams Unfold!</Typography>
                                    <Typography sx={{ fontSize: "15px", color: "#777777", margin: "" }} mt={2}>Welcome to Rajhans Maxton, where dreams unfold in the heart of Vesu. This premier residential development offers exquisite 3 BHK luxury residences designed to provide an unparalleled living experience. If you a...</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={12} md={12} spacing={4} sx={{ margin: "30px 0" }}>
                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                <Box sx={{ backgroundImage: `url(${blogImg1})`, width: "100%", height: "250px", backgroundSize: "cover", borderRadius: "0px 90px 0px 90px", boxShadow: "-20px -20px 2px #B57E0A", alignItems: "end", display: "flex", flex: "column", justifyContent: "end" }}>
                                    <Button sx={{ bgcolor: "#B57E0A", color: "#fff", border: "2px solid #B57E0A", transition: "0.7s", borderRadius: "0px 20px 0px 20px", marginTop: "auto", "&:hover": { color: "#B57E0A" } }}>Read More</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={8} sx={{justifyContent:"center",display:"flex",alignItems:"center"}}>
                                <Box>
                                    <Typography sx={{ fontSize: "18px", color: "#B57E0A", fontWeight: "500", marginBottom: "5px", paddingBottom: "8px" }} mt={4}>8 jun, 2024</Typography>
                                    <Typography sx={{ fontSize: "22px", fontWeight: "600", lineHeight: "36px" }}>Discover Luxurious Living at Rajhans Maxton: Where Dreams Unfold!</Typography>
                                    <Typography sx={{ fontSize: "15px", color: "#777777", margin: "" }} mt={2}>Welcome to Rajhans Maxton, where dreams unfold in the heart of Vesu. This premier residential development offers exquisite 3 BHK luxury residences designed to provide an unparalleled living experience. If you a...</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={12} md={12} spacing={4} sx={{ margin: "30px 0" }}>
                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                <Box sx={{ backgroundImage: `url(${blogImg1})`, width: "100%", height: "250px", backgroundSize: "cover", borderRadius: "0px 90px 0px 90px", boxShadow: "-20px -20px 2px #B57E0A", alignItems: "end", display: "flex", flex: "column", justifyContent: "end" }}>
                                    <Button sx={{ bgcolor: "#B57E0A", color: "#fff", border: "2px solid #B57E0A", transition: "0.7s", borderRadius: "0px 20px 0px 20px", marginTop: "auto", "&:hover": { color: "#B57E0A" } }}>Read More</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={8} sx={{justifyContent:"center",display:"flex",alignItems:"center"}}>
                                <Box>
                                    <Typography sx={{ fontSize: "18px", color: "#B57E0A", fontWeight: "500", marginBottom: "5px", paddingBottom: "8px" }} mt={4}>8 jun, 2024</Typography>
                                    <Typography sx={{ fontSize: "22px", fontWeight: "600", lineHeight: "36px" }}>Discover Luxurious Living at Rajhans Maxton: Where Dreams Unfold!</Typography>
                                    <Typography sx={{ fontSize: "15px", color: "#777777", margin: "" }} mt={2}>Welcome to Rajhans Maxton, where dreams unfold in the heart of Vesu. This premier residential development offers exquisite 3 BHK luxury residences designed to provide an unparalleled living experience. If you a...</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={12} md={12} spacing={4} sx={{ margin: "30px 0" }}>
                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                <Box sx={{ backgroundImage: `url(${blogImg1})`, width: "100%", height: "250px", backgroundSize: "cover", borderRadius: "0px 90px 0px 90px", boxShadow: "-20px -20px 2px #B57E0A", alignItems: "end", display: "flex", flex: "column", justifyContent: "end" }}>
                                    <Button sx={{ bgcolor: "#B57E0A", color: "#fff", border: "2px solid #B57E0A", transition: "0.7s", borderRadius: "0px 20px 0px 20px", marginTop: "auto", "&:hover": { color: "#B57E0A" } }}>Read More</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={8} sx={{justifyContent:"center",display:"flex",alignItems:"center"}}>
                                <Box>
                                    <Typography sx={{ fontSize: "18px", color: "#B57E0A", fontWeight: "500", marginBottom: "5px", paddingBottom: "8px" }} mt={4}>8 jun, 2024</Typography>
                                    <Typography sx={{ fontSize: "22px", fontWeight: "600", lineHeight: "36px" }}>Discover Luxurious Living at Rajhans Maxton: Where Dreams Unfold!</Typography>
                                    <Typography sx={{ fontSize: "15px", color: "#777777", margin: "" }} mt={2}>Welcome to Rajhans Maxton, where dreams unfold in the heart of Vesu. This premier residential development offers exquisite 3 BHK luxury residences designed to provide an unparalleled living experience. If you a...</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} lg={12} md={12} spacing={4} sx={{ margin: "30px 0" }}>
                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                <Box sx={{ backgroundImage: `url(${blogImg1})`, width: "100%", height: "250px", backgroundSize: "cover", borderRadius: "0px 90px 0px 90px", boxShadow: "-20px -20px 2px #B57E0A", alignItems: "end", display: "flex", flex: "column", justifyContent: "end" }}>
                                    <Button sx={{ bgcolor: "#B57E0A", color: "#fff", border: "2px solid #B57E0A", transition: "0.7s", borderRadius: "0px 20px 0px 20px", marginTop: "auto", "&:hover": { color: "#B57E0A" } }}>Read More</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={8} sx={{justifyContent:"center",display:"flex",alignItems:"center"}}>
                                <Box>
                                    <Typography sx={{ fontSize: "18px", color: "#B57E0A", fontWeight: "500", marginBottom: "5px", paddingBottom: "8px" }} mt={4}>8 jun, 2024</Typography>
                                    <Typography sx={{ fontSize: "22px", fontWeight: "600", lineHeight: "36px" }}>Discover Luxurious Living at Rajhans Maxton: Where Dreams Unfold!</Typography>
                                    <Typography sx={{ fontSize: "15px", color: "#777777", margin: "" }} mt={2}>Welcome to Rajhans Maxton, where dreams unfold in the heart of Vesu. This premier residential development offers exquisite 3 BHK luxury residences designed to provide an unparalleled living experience. If you a...</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box display={"flex"} justifyContent={"center"} margin={"50px 0"}>
                            <Box sx={{ bgcolor: "#bb8c26", color: "#fff", border: "2px solid #bb8c26", borderRadius: "20px", alignSelf: "flex-start",padding:"7px 30px" }}>Show More Blogs</Box>
                        </Box>
                    </Box>
                </Container>
            </Box>

        </div>
    )
}

export default BlogSection1